import React from 'react';
import { isEmpty } from 'lodash';
import { trackEvents } from "../helper";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { Link } from 'gatsby';
import LinkMui from '@mui/material/Link';

// Icons
import Facebook from '@mui/icons-material/Facebook';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import Google from './google.inline.svg';
import Alert from '../alert';

const TermsText = ({ dialogTermsProps, termsText, termsLink, termsLabel, privacyLink, privacyLabel, termsLinkProps }) => {
    return(
        <Typography classes={{root: "dialogTerms"}} {...dialogTermsProps}>
            {termsText} {` `}
            {(!isEmpty(termsLink)) && <LinkMui href={termsLink} {...termsLinkProps}>{termsLabel}</LinkMui>}
            {(!isEmpty(termsLink) && !isEmpty(privacyLink)) && <> and </>}
            {(!isEmpty(privacyLink)) && <LinkMui href={privacyLink} {...termsLinkProps}>{privacyLabel}</LinkMui>}.
        </Typography>
    )
}

const StyledDialogContent = (props) => {
    const {
        subtitle,
        showSocialLogin,
        showTerms,
        termsText,
        termsLinkProps,
        termsLink,
        termsLabel,
        privacyLink,
        privacyLabel,
        state,
        services,
        resetPassUrl,
        resetPassLabel,
        formActionLabel,
        dialogContentProps,
        facebookLogin
    } = props

    const {
        dialogContentTitleProps,
        dialogSocialButtonProps,
        dialogSocialButtonGoogleProps,
        dialogSocialButtonFBProps,
        dialogSeparatorProps,
        dialogSeparatorSpanProps,
        dialogFormControlProps,
        dialogTermsProps,
        dialogHelperTextProps,
        dialogActionsBtnProps,

    } = dialogContentProps

    // password field logic
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const [validation, setValidation] = React.useState({
        email: false,
        password: false
    });

    const handleChange = (prop) => (event) => {
        if (!event.target.value)
            setValidation({...validation, [prop]:true})
        else
            setValidation({...validation, [prop]:false})

        setValues({ ...values, [prop]: event.target.value });
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        let hasError = false;
        if (isEmpty(values)) {
            setValidation({
                email: true,
                password: true
            })
        } else {
            let validationAlt = {}
            let errotStatus;
            Object.entries(validation).map(([key, value]) => {
                if (!values[key]) {
                    errotStatus = true;
                    hasError = true;
                } else {
                    errotStatus = false;
                }
                validationAlt[key] = errotStatus;
            })
            setValidation(validationAlt)
        }

        if (!hasError) {
            values.email = (values.email || '').toLowerCase();
            services.loginWithEmail({...values});
        }
    };

    // Call social login
    const doSocialLogin = ( provider, eventId ) => {
        trackEvents({
          event: 'buttonClick',
          eventId: eventId
        })
        services.providerLogin(provider);
    }

    return(
        <DialogContent classes={{root: "dialogContent"}}>
            <DialogContentText classes={{root: "dialogContentTitle"}} {...dialogContentTitleProps}>
                {subtitle}
            </DialogContentText>
            {showSocialLogin &&
                <Box className={"dialogSocialButtonCont"}>
                    <Button
                        {...dialogSocialButtonProps}
                        classes={{root: "dialogSocialButton"}}
                        onClick={ () => {doSocialLogin('google', 'myAccountSigninGoogle')}}
                        id="myAccountSigninGoogle"
                    >
                        <Google {...dialogSocialButtonGoogleProps} />
                    </Button>
                    {facebookLogin && (
                        <Button
                            {...dialogSocialButtonProps}
                            classes={{root: "dialogSocialButton"}}
                            onClick={ () => {doSocialLogin('facebook', 'myAccountSigninFacebook')}}
                            id="myAccountSigninFacebook"
                        >
                            <Facebook {...dialogSocialButtonFBProps} />
                        </Button>
                    )}
                </Box>
            }

            <Typography
                {...dialogSeparatorProps}
                classes={{root: "dialogSeparator"}}
            >
                <Typography classes={{root: "dialogSeparatorSpan"}} {...dialogSeparatorSpanProps}>or login with email</Typography>
            </Typography>

            <form noValidate autoComplete="off" id="myAccountSigninForm">
                <Alert type="alert" message={state.error ? state.message : ""} severity={state.error ? 'error' : 'success'} />
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="login-username"
                        type="text"
                        label="Email Address"
                        classes={{root: "dialogFormControlElem"}}
                        onChange={handleChange('email')}
                        error={validation['email']}
                        required={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="login-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        label="Password"
                        onChange={handleChange('password')}
                        aria-describedby="reset-password"
                        classes={{root: "dialogFormControlElem"}}
                        error={validation['password']}
                        required={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <FormHelperText
                        id="reset-password"
                        children={<Link to={`${resetPassUrl}`} className="link-dialog">{resetPassLabel}</Link>}
                        classes={{root: "dialogHelperText"}}
                        {...dialogHelperTextProps}
                    />
                </FormControl>

                {showTerms && termsText &&
                    <TermsText
                        dialogTermsProps={dialogTermsProps}
                        termsText={termsText}
                        termsLinkProps={termsLinkProps}
                        termsLink={termsLink}
                        termsLabel={termsLabel}
                        privacyLink={privacyLink}
                        privacyLabel={privacyLabel}
                    />
                }

                <DialogActions classes={{root: "dialogActions"}}>
                    <Button type="submit" disabled={state.loading || false} onClick={handleSubmit} {...dialogActionsBtnProps} classes={{root: "dialogActionsBtn"}} id="myAccountSigninSubmit">
                        {formActionLabel}
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    )
};

export default StyledDialogContent;
