import React from "react"
import { withSubtheme } from "../../../StarberryComponentsMui"
import Flexbox from "@mui/material/Grid"
import CreatePass from "../modals/CreatePass"
import { useLocation } from "@reach/router"
import { useQuery } from "../utils"
import { API_URL } from "../../../constants/urls"

const MyAccountResetPass = withSubtheme(props => {
    // get location
    const location = useLocation()
    const uid = useQuery(location, "uid")
    const email_date = useQuery(location, "email_date")
    let trackUrl = `${API_URL}/stb-forms/${uid}/2/tracking-pixel.png`
    if (email_date)
        trackUrl = `${API_URL}/stb-users/${uid}/2/tracking-pixel.png?email_date=${email_date}`

    return (
        <Flexbox container>
            {uid && <img src={`${trackUrl}`} style={{ display: "none" }} />}
            <Flexbox item xs={12}>
                <CreatePass onClose={() => void 0} />
            </Flexbox>
        </Flexbox>
    )
}, "resetPassPage")

export default React.memo(MyAccountResetPass)
