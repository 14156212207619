/* eslint-disable import/first */

import React from "react"
import { Router } from "@reach/router"

// Ref: https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/

// Importing individual components like this (rather than
// `import {...} from '@material-ui/core';` ) makes for more efficient
// bundling, in theory :
// see https://material-ui.com/guides/minimizing-bundle-size/

import { useTheme, withSubtheme } from "../core/StarberryComponentsMui"
import {
    MYACCOUNT_DASHBOARD,
    MYACCOUNT_INBOX,
    MYACCOUNT_CALENDAR,
    MYACCOUNT_PROFILE,
    MYACCOUNT_AUTHCALLBACK,
    MYACCOUNT_LOGIN,
    MYACCOUNT_SIGNUP,
    MYACCOUNT_FORGOT_PASSWORD,
    MYACCOUNT_RESET_PASSWORD,
    MYACCOUNT_CREATE_PASSWORD,
    MYACCOUNT_UNSUBSCRIBE,
    MYACCOUNT_MYPROPERTY,
    MYACCOUNT_2FACTOR_AUTH
} from "../core/constants/urls";

import PrivateRoute from "../core/components/MyAccount/hoc/PrivateRoute"
import Login from "../core/components/MyAccount/pages/login"
import SignUp from "../core/components/MyAccount/pages/signUp"
import TwoFactorAuth from "../core/components/MyAccount/pages/twofactorauth"
import Dashboard from "../core/components/MyAccount/pages"
import Dashboard2 from "../core/components/MyAccount/pages/index2"
import Inbox from "../core/components/MyAccount/pages/inbox"
import Calendar from "../core/components/MyAccount/pages/calendar"
import Profile from "../core/components/MyAccount/pages/profile"
import AuthCallback from "../core/components/MyAccount/user/authcallback"
import ResetPassEmail from "../core/components/MyAccount/pages/resetPassEmail";
import ResetPass from "../core/components/MyAccount/pages/resetPass";
import CreatePass from "../core/components/MyAccount/pages/createPass";
import Unsubscribe from "../core/components/MyAccount/pages/unsubscribe";
import PageHeaderHelmet from "../core/components/pageHeader";
import MyProperty from "../core/components/MyAccount/pages/my-property"

import _myAccount from "../core/sample/myAccount.yaml"

const MenuRouterHome = withSubtheme(props => {
    const { theme, path, pageVariant, location } = props

    switch (pageVariant) {
        case "Dashboard2":
            return(
                <PrivateRoute
                    path={path}
                    component={Dashboard2}
                    location={location}
                />
            )
        case "MyProperty":
            return(
                <PrivateRoute
                    path={MYACCOUNT_MYPROPERTY}
                    component={MyProperty}
                    location={location}
                    theme={theme}
                />
            )
        default:
            return(
                <PrivateRoute
                    path={path}
                    component={Dashboard}
                    location={location}
                />
            )
    }
    // return link
}, "menuRouterHomePage")


const MyAccountPages = () => {

    const theme = useTheme()

    // In real use, the people in 'persons' will probably come from CMS,
    // while properties come from another data source.
    const myAccount =
        theme.getProp("subthemes.myAccount.sampleData") ?? _myAccount

    return (
        <>
            <PageHeaderHelmet theme={theme} />
            <Router>
                <AuthCallback path={MYACCOUNT_AUTHCALLBACK} />
                <Login path={MYACCOUNT_LOGIN} myAccount={myAccount} theme={theme} />
                <SignUp path={MYACCOUNT_SIGNUP} myAccount={myAccount} theme={theme} />
                <ResetPassEmail path={MYACCOUNT_FORGOT_PASSWORD} myAccount={myAccount} theme={theme} />
                <ResetPass path={MYACCOUNT_RESET_PASSWORD} myAccount={myAccount} theme={theme} />
                <CreatePass path={MYACCOUNT_CREATE_PASSWORD} myAccount={myAccount} theme={theme} />
                <Unsubscribe path={MYACCOUNT_UNSUBSCRIBE} myAccount={myAccount} theme={theme} />
                <TwoFactorAuth path={MYACCOUNT_2FACTOR_AUTH} myAccount={myAccount} theme={theme} />
                <MenuRouterHome path={MYACCOUNT_DASHBOARD} />
                <PrivateRoute
                    path={MYACCOUNT_INBOX}
                    component={Inbox}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_CALENDAR}
                    component={Calendar}
                    myAccount={myAccount}
                    theme={theme}
                />
                <PrivateRoute
                    path={MYACCOUNT_PROFILE}
                    component={Profile}
                    myAccount={myAccount}
                    theme={theme}
                />
            </Router>
        </>
    )
}

export default MyAccountPages
