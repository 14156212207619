import React from 'react';
import { withSubtheme } from '../../../StarberryComponentsMui';
import Grid from '@mui/material/Grid';
import TwoFactorAuth from "../modals/TwoFactorAuth";

const MyAccountTwoFactorAuth = withSubtheme((props) => {
    return (
        <Grid container>
            <Grid item xs={12}>
                <TwoFactorAuth onClose={() => void 0} />
            </Grid>
        </Grid>
    );
}, 'resetPassEmailPage')

export default React.memo(MyAccountTwoFactorAuth);
