import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FormControl from '@mui/material/FormControl';
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import DialogActions from '@mui/material/DialogActions';
import LinkMui from '@mui/material/Link';

// Icons
import PasswordIcon from '@mui/icons-material/Password';
import Alert from '../alert';;

const TermsText = ({ dialogTermsProps, termsText, termsLink, termsLabel, privacyLink, privacyLabel, termsLinkProps }) => {
    return(
        <Typography classes={{root: "dialogTerms"}} {...dialogTermsProps}>
            {termsText} {` `}
            {(!isEmpty(termsLink)) && <LinkMui href={termsLink} {...termsLinkProps}>{termsLabel}</LinkMui>}
            {(!isEmpty(termsLink) && !isEmpty(privacyLink)) && <> and </>}
            {(!isEmpty(privacyLink)) && <LinkMui href={privacyLink} {...termsLinkProps}>{privacyLabel}</LinkMui>}.
        </Typography>
    )
}

const StyledDialogContent = (props) => {

    const {
        subtitle,
        state,
        services,
        formActionLabel,
        dialogContentProps
    } = props

    const {
        dialogContentTitleProps,
        dialogFormControlProps,
        dialogActionsBtnProps,
    } = dialogContentProps

    useEffect(() => {
      services.sendLoginOtp();
    }, [])

    // password field logic
    const [values, setValues] = React.useState({
        showPassword: false,
    });

    const [validation, setValidation] = React.useState({
        otp: false
    });

    const handleChange = (prop) => (event) => {
        if (!event.target.value)
            setValidation({...validation, [prop]:true})
        else
            setValidation({...validation, [prop]:false})

        setValues({ ...values, [prop]: event.target.value });
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        let hasError = false;
        if (!values?.otp) {
            hasError = true;
            setValidation({
                otp: true
            });
        }

        if(!hasError) {
            values.otp = (values.otp || '').toLowerCase();
            services.verifyOtpPassword({
                otp: values.otp
            });
        }
    };

    return(
        <DialogContent classes={{root: "dialogContent"}}>
            <DialogContentText classes={{root: "dialogContentTitle"}} {...dialogContentTitleProps}>
                {subtitle}
            </DialogContentText>

            <form noValidate autoComplete="off" id="myAccountResetPassEmailForm">
                <Alert type="alert" message={state.message} severity={state.error ? 'error' : 'success'} />
                <FormControl {...dialogFormControlProps}>
                    <TextField
                        id="login-username"
                        type="password"
                        label="Enter the OTP password"
                        classes={{root: "dialogFormControlElem"}}
                        onChange={handleChange('otp')}
                        error={validation['otp']}
                        required={true}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <PasswordIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>

                <DialogActions classes={{root: "dialogActions"}}>
                    <Button disabled={state.loading || false} onClick={handleSubmit} {...dialogActionsBtnProps} classes={{root: "dialogActionsBtn"}} id="myAccountResetPassEmailSubmit">
                        {formActionLabel}
                    </Button>
                </DialogActions>
            </form>
        </DialogContent>
    )
};

export default StyledDialogContent;
