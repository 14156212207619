import * as React from "react"
import Activities from "../activities"
import NoActions from "../progress/NoActions"
import Alert from "../alert"
import RecentActivitiesSkeleton from "./recentActivitiesSkeleton"
import { ComponentWithPagination } from "../pagination"
import { useGetRecentActivityQuery } from "./../../../redux/services/property"
import { isAuthenticated } from "../../../services/store/utils"
import { collectResponseMessage } from "../../../redux/utils"

const RecentActivityInit = ({ props }) => {
    const authUser = isAuthenticated()

    const {
        data: activitiesData,
        error,
        isError,
        isLoading,
        isFetching,
    } = useGetRecentActivityQuery({}, { skip: !authUser })

    const data = props?.data || activitiesData || []

    if (isLoading && !data.length) {
        return <RecentActivitiesSkeleton />
    }

    if (isError) {
        const errorMsg = collectResponseMessage(data, error, isError, "")
        return <Alert type={`alert`} {...errorMsg} />
    }

    if (!isLoading && !data.length) {
        return (
            <NoActions
                title="There are no recent activities"
                // message="At the moment all the actions are closed..."
                icon="recentActivitiesIcon"
            />
        )
    }

    return (
        <ComponentWithPagination
            {...props}
            component={Activities}
            data={data}
        />
    )
}

const RecentActivity = React.memo(props => {
    return <RecentActivityInit props={props} />
})

export default RecentActivity
