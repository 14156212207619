import React from "react"
import { sampleSize, isEmpty } from "lodash"
import { withSubtheme } from "../../../StarberryComponentsMui"
import Grid from "@mui/material/Grid"
import Container from "@mui/material/Container"
import Welcome from "../user/welcome"
import Tabs from "../tabs"
import range from "lodash/range"
import {
    MYACCOUNT_MYPROPERTY_URLS,
    MYACCOUNT_PROFILE,
} from "../../../constants/urls"
import { setValuesToUrl } from "../helper/urls"
import { getPropertyText } from "../helper"

import PropertyResultComponent from "../property/results"
import { useAuthState } from "../../../services"
import RecentActivities from "../recentActivities"
import ValuationModule from "../simpleModule/valuation"
import ContactModuleComp from "../pages/my-property/common/contactModule"
import PendingItems from "../pendingItems"
import PendingActions from "../pendingActions"
import ModuleRender from "../modules/moduleRender"
import CircularProgress from "../progress/CircularProgress"
import { hasPersona } from "../../../services/store/api"
import { getData } from "../../../services"
import {
    useGetNegotiatorDetailsQuery,
    useGetMyPropertyListQuery,
} from "../../../redux/services/property"

import { doRedirect } from "../utils"
import DesktopPropertyCardSkeleton from "../../../StarberryComponentsMui/components/Skeleton/DesktopPropertyCardSkeleton"
import Stack from "@mui/material/Stack"
import Skeleton from "@mui/material/Skeleton"
import { isAction } from "@reduxjs/toolkit"
import { isAuthenticated } from "../../../services/store/utils"
import { collectResponseMessage } from "../../../redux/utils"
import Alert from "../alert"

const PropertyCards = React.memo(props => {
    const { mypropertiesData, loading, isError, error } = props

    React.useEffect(() => {
        if (
            isEmpty(mypropertiesData?.buying) &&
            isEmpty(mypropertiesData?.selling) &&
            isEmpty(mypropertiesData?.letting) &&
            isEmpty(mypropertiesData?.renting) &&
            loading === false
        ) {
            const getApiData = async () => {
                try {
                    const data = await hasPersona()
                    if (!data.data) doRedirect(MYACCOUNT_PROFILE)
                } catch (e) {
                    console.log("Error => ", e.response)
                }
            }
            if (!getData("profilePopup")) getApiData()
        }
    }, [mypropertiesData])

    const selling = React.useMemo(() => {
        if (!loading && mypropertiesData?.selling) {
            const result = []
            Object.entries(mypropertiesData.selling).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail) return
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, {
                    persona: "selling",
                    pid: propertyDetail?.crm_id,
                })
                result.push({
                    ...propertyDetail,
                    link,
                    offerText: getPropertyText(value),
                })
            })

            return result
        }

        return []
    }, [mypropertiesData?.selling, loading])

    const letting = React.useMemo(() => {
        if (!loading && mypropertiesData?.letting) {
            const result = []
            Object.entries(mypropertiesData.letting).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail) return
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, {
                    persona: "letting",
                    pid: propertyDetail?.crm_id,
                })
                result.push({
                    ...propertyDetail,
                    link: link,
                    offerText: getPropertyText(value),
                })
            })

            return result
        }

        return []
    }, [mypropertiesData?.letting, loading])

    const renting = React.useMemo(() => {
        if (!loading && mypropertiesData?.renting) {
            const result = []
            Object.entries(mypropertiesData.renting).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail) return
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, {
                    persona: "renting",
                    pid: propertyDetail?.crm_id,
                })
                result.push({ ...propertyDetail, link: link })
            })

            return result
        }

        return []
    }, [mypropertiesData?.renting, loading])

    const buying = React.useMemo(() => {
        if (!loading && mypropertiesData?.buying) {
            const result = []
            Object.entries(mypropertiesData.buying).map(([key, value]) => {
                let propertyDetail = value?.property_detail || {}
                if (!value?.property_detail) return
                let link = setValuesToUrl(MYACCOUNT_MYPROPERTY_URLS, {
                    persona: "buying",
                    pid: propertyDetail?.crm_id,
                })
                result.push({ ...propertyDetail, link: link })
            })

            return result
        }

        return []
    }, [mypropertiesData?.buying, loading])

    const [properties, viewMoreLink] = React.useMemo(() => {
        let all = [...selling, ...letting, ...renting, ...buying]

        if (all.length > 3) {
            return [sampleSize(all, 3), "View All"]
        }

        return [all, ""]
    }, [selling, letting, renting, buying])

    if (loading) {
        return (
            <Stack direction="column" spacing={2} mb={6}>
                <Stack direction="row" justifyContent="space-between">
                    <Skeleton variant="text" width={100} height={30} />
                    <Skeleton variant="text" width={80} height={30} />
                </Stack>
                <div>
                    <Grid container spacing={4}>
                        {range(0, 2).map(index => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={4}
                                sx={{
                                    display: {
                                        xs: index > 0 ? "none" : "block",
                                        sm: index > 1 ? "none" : "block",
                                    },
                                }}
                            >
                                <DesktopPropertyCardSkeleton />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Stack>
        )
    }

    if (isError) {
        const errorMsg = collectResponseMessage(
            mypropertiesData,
            error,
            isError,
            ""
        )
        return <Alert type={`alert`} {...errorMsg} />
    }

    return (
        <PropertyResultComponent
            properties={properties}
            title="My Property"
            moreLabel={viewMoreLink}
            moreLinkUrl="/my-property"
            titleLinkMoreTooltipProps={{ title: "View more" }}
        />
    )
})

const actionTabs = [
    {
        id: "tab1",
        name: "Pending Actions",
        content: <PendingActions />,
    },
    {
        id: "tab2",
        name: "Recent Activity",
        content: <RecentActivities />,
    },
]

const ActionComp = React.memo(() => (
    <Tabs data={actionTabs} fullWidthTabs={true} />
))

const MyAccount2Index = withSubtheme(props => {
    const { theme, hideValuationModule, showContactModule } = props
    const authUser = isAuthenticated()
    const { services: authServices } = useAuthState()
    const staticModules = theme.getProp("staticModules")

    const { data: negotiator_info } = useGetNegotiatorDetailsQuery(
        {},
        { skip: !authUser }
    )

    const {
        data: mypropertiesData,
        isLoading,
        isError,
        error,
        isFetching,
    } = useGetMyPropertyListQuery({}, { skip: !authUser })

    React.useEffect(() => {
        authServices.updateState({
            pageTitle: `Dashboard`,
            pageRightCustomComponent: null,
            pageRightDrawer: null,
            pageLeftIcon: false,
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const showValuation = hideValuationModule ? hideValuationModule : false // isEmpty(mypropertiesData.selling);
    const showContact = showContactModule ? showContactModule : false

    return (
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Welcome />
                </Grid>
                <Grid item xs={12}>
                    <PropertyCards
                        mypropertiesData={mypropertiesData}
                        loading={isLoading}
                        isError={isError}
                        error={error}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={8}>
                            <ActionComp />
                            <PendingItems
                                title="Sales Progress"
                                data={props?.sales_progression || []}
                            />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={12}>
                                    {!showValuation && <ValuationModule />}
                                    {showContact && (
                                        <ContactModuleComp
                                            moduleTitle="Contact your agent"
                                            data={negotiator_info}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {staticModules && staticModules.length && (
                    <Grid item xs={12}>
                        <ModuleRender staticModules={staticModules} />
                    </Grid>
                )}
            </Grid>
        </Container>
    )
}, "homePage2")

export default React.memo(MyAccount2Index)
