import React from "react";
import Link from '@mui/material/Link';
import { StarberryIcons } from '../../icons';
import { WEBSITE_URL } from "../../../../constants/urls";
import { getData } from "../../../../services";
import { withSubtheme } from '../../../../StarberryComponentsMui';
import defaults from './defaults'
import { Typography, Grid } from '@mui/material';

const BackToWebsite = withSubtheme(props => {
    const {
        className
    } = props
    
    let authReturn = getData('auth_return_origin') || getData('auth_return');
    if (authReturn && !authReturn.match('http|s')) {
        authReturn = false;
    }
    const websiteLink = authReturn || WEBSITE_URL;

    return (
        <Typography classes={{root: className}}>
            <div className="linkBackToWebsite">
                <Link href={websiteLink}>
                    <StarberryIcons iconName="arrowBack" /><span className="mobile">Website</span><span className="desktop">Back to website</span>
                </Link>
            </div>
        </Typography>
    )
}, 'backToWebsite', defaults)

export default BackToWebsite;