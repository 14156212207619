import React from "react"
import MyAccountIndex from "../containers/index"
import GlobalLayout from "../GlobalLayout"

const MyAccountIndex2Page = () => {
    return (
        <GlobalLayout>
            <MyAccountIndex />
        </GlobalLayout>
    )
}

export default React.memo(MyAccountIndex2Page)
